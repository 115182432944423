export function dateToTextPL(date, withYear = true) {
  const partials = date.split("-");
  const months = ['stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'];

  return parseInt(partials[2]) + ' ' + months[parseInt(partials[1]) - 1] + (withYear ? (' ' + partials[0]) : '');
}

export function getLastWorkingDayDate() {
  let now = new Date();

  switch (now.getDay()) {
    case 0:
      now = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      )
      break;
    case 6:
      now = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 2
      )
      break;
  }

  return formatDate(now)
}

export function formatDate(date) {
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth()+1).toString().padStart(2, "0");
  let year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export function isInWorkingHours() {
  const now = new Date();
  if (now.getDay() === 0 || now.getDay() === 6) return false;
  return !(now.getHours() < 9 || now.getHours() > 21);
}
